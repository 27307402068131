<template>
  <div class="has-background-white">
    <b-table
      :data="data"
      :loading="loading"
      hoverable
      striped
      class="base-table"
      @cellclick="$emit('rowClick', $event)"
    >
      <b-table-column
        v-for="column in columns"
        :key="column.field"
        :field="column.field"
        :label="column.label"
        :centered="column.centered || false"
        :width="column.width"
        v-slot="props"
      >
        <div v-if="column.field === 'actions'">
          <button
            class="button is-info is-small is-outlined"
            @click.prevent="routeChange('SpectraList', props.row.id)"
          >Спектры</button>
        </div>
        <div v-else>
          {{ getValueByPath(props.row, column.field) }}
        </div>
      </b-table-column>
    </b-table>
    <section class="mt-5 p-3">
      <b-pagination
        :total="total"
        :per-page="perPage"
        order="is-centered"
        icon-pack="fas"
        :current="currentPage"
        class="base-table__pagination"
        @change="changePage"
      >
        <template #previous="props">
          <b-pagination-button
            :page="props.page"
            @click="changePage(currentPage-1)"
          >
            <font-awesome-icon :icon="['fas', 'fa-chevron-left']" />
          </b-pagination-button>
        </template>
        <template #next="props">
          <b-pagination-button
            :page="props.page"
            @click="changePage(currentPage+1)"
          >
            <font-awesome-icon :icon="['fas', 'fa-chevron-right']" />
          </b-pagination-button>
        </template>
      </b-pagination>
    </section>
  </div>
</template>

<script>
export default {
  name: "BaseTable",
  props: {
    data: Array,
    columns: Array,
    loading: Boolean,
    total: Number,
    perPage: Number,
    currentPage: Number,
    withOperations: Boolean
  },
  methods: {
    changePage(page) {
      this.$emit('change', page)
    },
    getValueByPath(obj, path) {
      return path.split('.').reduce((o, i) => o ? o[i] : null, obj)
    },
    routeChange(name, id) {
      this.$router.push({ name, params: { id } })
    }
  }
};
</script>

<style lang="scss">
.base-table {
  min-height: 50vh;

  & tbody > tr {
    cursor: pointer;
  }

  &__pagination .pagination-link.is-current {
    background-color: #007BFF;
    border-color: #007BFF;
  }
}
</style>
